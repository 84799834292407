<template>
  <main>
    <!-- The stage from the config page. -->
    <BlokkliProvider
      v-if="homeConfig"
      v-bind="homeConfigProviderProps"
      edit-label="Edit stage"
      isolate
    >
      <BlokkliField
        name="field_paragraphs_stage"
        :list="homeConfig?.stageParagraphs"
        class="md:layout-grid home-stage relative mx-auto max-w-screen-xxxl bg-grey-light-03 mobile-only:m-16 md:mt-24"
      />
    </BlokkliProvider>
    <!-- The content from the node. -->
    <BlokkliProvider v-bind="nodeProviderProps">
      <BlokkliField name="field_paragraphs" :list="node?.paragraphs" />
    </BlokkliProvider>
  </main>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'
import type {
  HomeConfigFragment,
  NodePageFragment,
  RouteHomeQuery,
} from '#graphql-operations'

definePageMeta({
  name: 'home',
})

const nuxtRoute = useRoute()
const nuxtApp = useNuxtApp()

// Get the data.
const { data: query } = await useAsyncData<RouteHomeQuery>(
  nuxtRoute.path,
  async () => {
    const { data } = await useCachedGraphqlQuery('routeHome', {
      path: nuxtRoute.path,
    })
    return data
  },
  {
    getCachedData: (key) => {
      return nuxtApp.payload.data[key]
    },
  },
)

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query)

const homeConfig = computed<HomeConfigFragment | undefined>(
  () => query.value?.homeConfig,
)
const nodeProviderProps = buildBlokkliProps(node)
const homeConfigProviderProps = buildBlokkliProps(
  homeConfig.value,
  'translatable_config_pages',
  'home',
)

// Cache the front page for 20 minutes.
useRouteCache((ctx) => ctx.setMaxAge(60 * 20).setCacheable())

const { trackPageView } = useAnalyticsTracking()
trackPageView()
</script>

<script lang="ts">
export default {
  name: 'Home',
}
</script>

<style lang="scss" scoped>
/**
  * This is the easiest way to get the "border" effect on the stage.
  * The stage is full width and because we want to keep the grid of the stage
  * correct we can't set a margin or padding. By placing two white divs on the
  * left and right we can make it look like a margin.
  */
.home-stage {
  @screen md {
    &:before,
    &:after {
      content: '';
      @apply pointer-events-none absolute top-0 h-full w-32 bg-white;
    }

    &:before {
      @apply left-0;
    }
    &:after {
      @apply right-0;
    }
  }

  @screen xl {
    &:before,
    &:after {
      @apply hidden;
    }
  }
}

:deep(.paragraph),
.paragraph {
  &:first-of-type {
    @apply mt-[135px] md:mt-80;
  }

  &:last-of-type {
    @apply mb-[82px] md:mb-[192px];
  }

  @include paragraph-spacing;
}
</style>
